// Copyright (C) 2021 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig } from 'axios';
import store from '../../store';

export function getApiKeys(): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: '/apikeys/',
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

interface ApiKeyData {
  name: string;
  scopes: string[];
  unit_id?: string;
}

export function createApiKey(apiKeyData: ApiKeyData): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: '/apikeys/',
      method: 'POST',
      data: apiKeyData,
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

export function editApiKey(id: string, apiKeyData: ApiKeyData): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/apikeys/${id}/`,
      method: 'PATCH',
      data: apiKeyData,
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

export function deleteApiKey(id: string): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/apikeys/${id}/`,
      method: 'DELETE',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}

export function getApiKeyTag(id: string): Promise<string> {
  return new Promise((resolve) => {
    const { authorizationToken } = store.getters;

    const requestOptions: AxiosRequestConfig = {
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/apikeys/${id}/tags/`,
      method: 'GET',
      headers: {
        Authorization: authorizationToken,
      },
    };

    axios(requestOptions).then((response) => {
      const { data } = response;

      resolve(data);
    });
  });
}
