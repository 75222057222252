<template>
  <v-container
    class="d-flex flex-column px-6 pt-6 pb-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-col
      class="mb-9"
      style="flex: 0"
    >
      <v-row class="mt-0 mb-6 text-h5 font-weight-bold textBlack--text">
        {{ $t('deconve.setting.apiKey.manageKeys') }}
      </v-row>
      <v-row>
        <div
          dense
          class="d-flex mb-3 align-center"
          style="flex: 1"
        >
          <input-text
            v-model="search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />
        </div>
      </v-row>
    </v-col>
    <v-col
      class="rounded pa-6"
      style="border: 1px solid; overflowY: auto"
      :style="{
        borderColor: $vuetify.theme.themes.light.border,
        backgroundColor: $vuetify.theme.themes.light.inputBackground,
      }"
    >
      <v-row class="mb-3 justify-space-between align-center">
        <span
          class="subtitle-2 text-sm-subtitle-1 font-weight-medium neutralPrimary--text"
        >
          {{ $t('deconve.setting.apiKeys') }}
        </span>
        <rectangle-button
          color="primary"
          icon="mdi-plus"
          data-cy="apikey-button-add"
          :disabled="!$can('create', 'com.deconve.apikey')"
          @clicked="openApiKeysManager"
        >
          {{ $t('deconve.setting.apiKey.newKey') }}
        </rectangle-button>
      </v-row>
      <v-row>
        <v-data-table
          style="flex: 1; border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          :headers="headers"
          :search="search"
          :items="apikeys"
          :items-per-page="10"
          data-cy="apikey-data-table"
          class="elevation-0"
        >
          <template v-slot:[`item.created_at`]="{item}">
            <span>{{ item.created_at | moment('DD/MM/YYYY') }}</span>
          </template>
          <template v-slot:[`item.tags`]="{item}">
            <div
              class="d-flex"
              style="width: 100%; justify-content: center"
            >
              <div
                class="pa-2 d-flex flex-wrap"
                style="width: fit-content"
              >
                <tag
                  v-for="{tag} in item.tags"
                  :key="tag.id"
                  small
                  class="pr-1 pb-1"
                  :tag-id="tag.id"
                />
              </div>
            </div>
          </template>
          <template
            v-slot:[`item.actions`]="{item}"
          >
            <square-button
              outlined
              data-cy="apikey-button-more"
              content-class="ml-2"
              color="border"
              icon-color="neutral"
              icon-name="mdi-dots-vertical"
            >
              <v-list>
                <v-list-item
                  data-cy="apikey-button-edit"
                  :disabled="!$can('update', 'com.deconve.apikey')"
                  @click="openApiKeysManager(item)"
                >
                  {{ $t('deconve.edit') }}
                </v-list-item>
                <v-list-item
                  data-cy="apikey-button-delete"
                  :disabled="!$can('delete', 'com.deconve.apikey')"
                  @click="deleteApiKey(item.id)"
                >
                  {{ $t('deconve.delete') }}
                </v-list-item>
                <v-list-item
                  @click="seePermissions(item.scopes)"
                >
                  {{ $t('deconve.seePermissions') }}
                </v-list-item>
              </v-list>
            </square-button>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
    <api-keys-manager-dialog ref="apiKeysDialog" />
    <confirmation-dialog ref="confirmationDialog" />
    <scopes-manager-dialog ref="scopesDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.
import InputText from '../../components/InputText.vue';
import SquareButton from '../../components/SquareButton.vue';
import RectangleButton from '../../components/RectangleButton.vue';
import Tag from '../../components/Tag.vue';
import ApiKeysManagerDialog from '../../components/ApiKeysManagerDialog.vue';
import ConfirmationDialog from '../../components/ConfirmationDialog.vue';
import ScopesManagerDialog from '../../components/ScopesManagerDialog.vue';
import { getApiKeys, deleteApiKey, getApiKeyTag } from '../../api/apiKeys';

export default {
  name: 'ApiKeys',
  components: {
    InputText,
    RectangleButton,
    SquareButton,
    ApiKeysManagerDialog,
    ScopesManagerDialog,
    Tag,
    ConfirmationDialog,
  },
  data() {
    return {
      headers: [
        { text: this.$t('deconve.setting.apiKey.id'), value: 'name' },
        { text: this.$t('deconve.setting.apiKey.key'), value: 'key' },
        { text: this.$t('deconve.createdAt'), value: 'created_at' },
        { text: this.$t('deconve.tags'), value: 'tags', align: 'center' },
        {
          text: this.$t('deconve.actions'), value: 'actions', align: 'end', sortable: false,
        },
      ],
      apikeys: [],
      search: '',
    };
  },
  created() {
    this.getApiKeys();
  },
  methods: {
    openApiKeysManager(apiKey) {
      this.$refs.apiKeysDialog.open(apiKey).then((response) => {
        if (response) {
          this.getApiKeys();
        }
      });
    },
    seePermissions(scopes) {
      this.$refs.scopesDialog.open({
        title: this.$t('deconve.setting.apiKey.apiKeyPermissions'),
        scopes,
        locked: true,
      });
    },
    deleteApiKey(id) {
      this.$refs.confirmationDialog.open(
        this.$t('deconve.setting.apiKey.confirmDeletion.title'),
        this.$t('deconve.setting.apiKey.confirmDeletion.message'),
      ).then((isConfirmed) => {
        if (isConfirmed) {
          deleteApiKey(id).then(() => {
            this.getApiKeys();
          });
        }
      });
    },
    getApiKeys() {
      getApiKeys().then((response) => {
        this.apikeys = response;
        this.apikeys.forEach((apiKey, index) => {
          getApiKeyTag(apiKey.id).then((tags) => {
            const newApiKey = { ...apiKey };

            newApiKey.tags = tags;
            this.apikeys.splice(index, 1, newApiKey);
          });
        });
      });
    },
  },
};
</script>
