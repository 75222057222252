<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    persistent
    @keydown.esc="cancel"
  >
    <v-card class="px-6 py-8">
      <v-toolbar
        color="white"
        dense
        flat
        class="align-center"
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ id? $t('deconve.setting.apiKey.editApiKey') : $t('deconve.setting.apiKey.newApiKey') }}
        </v-toolbar-title>
        <v-spacer />
        <square-button
          v-if="$vuetify.breakpoint.mobile"
          menu-disabled
          icon-name="mdi-close"
          outlined
          color="transparentBackground"
          style="margin-top: auto"
          icon-color="neutral"
          @clicked="cancel"
        />
      </v-toolbar>
      <v-card-text
        v-if="!accessKey"
        class="px-4 mt-2"
      >
        <div class="d-flex justify-space-between align-center">
          <span
            class="font-weight-medium caption text-sm-body-2 mb-1"
          >
            {{ $t('deconve.setting.apiKey.key') }}
          </span>
        </div>
        <input-text
          v-model="keyName"
          data-cy="apikeymanager-input-name"
          :placeholder="$t('deconve.setting.apiKey.keyName')"
          @focused="isListActive = true"
        />
        <div class="d-flex justify-space-between align-center mt-4">
          <span
            class="font-weight-medium caption text-sm-body-2 mb-1"
          >
            {{ $t('deconve.tags') }}
          </span>
          <square-button
            v-if="tags.length > 0"
            menu-disabled
            icon-name="mdi-tag-plus-outline"
            outlined
            color="transparentBackground"
            data-cy="apikeymanager-addmore-tags"
            style="margin-top: auto"
            icon-color="neutral"
            @clicked="openTagModal"
          />
        </div>
        <rectangle-button
          v-if="tags.length === 0"
          icon="mdi-tag-plus-outline"
          outlined
          color="neutral"
          data-cy="apikeymanager-add-tags"
          @clicked="openTagModal"
        >
          {{ $t('deconve.tag.addTags') }}
        </rectangle-button>
        <div class="d-flex flex-wrap">
          <tag
            v-for="(tag, index) in tags"
            :key="tag.id"
            show-delete-label
            class="pa-1"
            :tag-id="tag.id"
            @delete="deleteTag(index)"
          />
          <div
            v-if="tags.length > 0"
            class="d-flex flex-column ml-2"
            style="flex: 1"
          />
        </div>
      </v-card-text>
      <v-card-text
        v-else
        class="pa-4"
      >
        <alert
          type="info"
        >
          {{ $t('deconve.setting.apiKey.alert.copyAccessKey') }}
        </alert>
        <span class="font-weight-medium caption text-sm-body-2">
          {{ $t('deconve.setting.apiKey.accessKey') }}
        </span>
        <div
          class="d-flex justify-space-between rounded"
          style="border: 1px solid"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
            backgroundColor: $vuetify.theme.themes.light.inputBackground
          }"
        >
          <textarea
            ref="key"
            :value="accessKey"
            readonly
            class="pa-2 neutral--text"
            style="overflow-wrap: anywhere; flex: 1; resize: none; height:84px"
          />
          <v-btn
            class="pa-0 rounded"
            small
            icon
            text
            data-cy="apikeymanager-copy"
            @click="copyKey"
          >
            <v-icon
              size="16"
              color="neutral"
            >
              {{ copied ? 'mdi-checkbox-multiple-marked' : 'mdi-checkbox-multiple-blank-outline' }}
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <rectangle-button
          v-if="!accessKey && !$vuetify.breakpoint.mobile"
          outlined
          color="neutral"
          data-cy="apikeymanager-cancel"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          v-if="!accessKey"
          data-cy="apikeymanager-confirm"
          color="primary"
          @clicked="confirm"
        >
          {{ id? $t('deconve.confirm') : $t('deconve.continue') }}
        </rectangle-button>
        <rectangle-button
          v-if="accessKey"
          data-cy="apikeymanager-close"
          color="primary"
          :disabled="!copied"
          @clicked="close"
        >
          {{ $t('deconve.close') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
    <scopes-manager-dialog ref="scopesDialog" />
    <tags-manager-dialog ref="tagsDialog" />
  </v-dialog>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import Tag from './Tag.vue';
import InputText from './InputText.vue';
import ScopesManagerDialog from './ScopesManagerDialog.vue';
import TagsManagerDialog from './TagsManagerDialog.vue';
import { createApiKey, editApiKey } from '../api/apiKeys';

import Alert from './Alert.vue';

export default {
  name: 'ApiKeysManagerDialog',
  components: {
    Tag,
    InputText,
    RectangleButton,
    SquareButton,
    ScopesManagerDialog,
    TagsManagerDialog,
    Alert,
  },
  data: () => ({
    id: '',
    keyName: '',
    tagsToDelete: [],
    tagsToAdd: [],
    tags: [],
    defaultTags: [],
    unitId: undefined,
    accessKey: '',
    copied: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
  }),
  methods: {
    open(apiKey) {
      this.resetData();

      if (apiKey) {
        this.keyName = apiKey.name;
        this.id = apiKey?.id;
        this.unitId = apiKey?.unit_id;
        this.defaultTags = apiKey.tags;
        this.getDefaultTags();

        if (!this.id) {
          this.tagsToAdd = [...this.tags];
        }
      }

      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    getDefaultTags() {
      if (this.defaultTags?.length > 0) {
        this.tags = this.defaultTags.map(({ tag }) => tag);
      } else {
        this.tags = [];
      }
    },
    editApiKey() {
      const tags = this.tags.map((tag) => tag.id);

      const apiKeyData = {
        name: this.keyName,
        scopes: [],
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tags,
      };

      editApiKey(this.id, apiKeyData).then((response) => {
        this.resolve(response);
        this.dialog = false;
      });
    },
    createApiKey() {
      this.$refs.scopesDialog.open({
        title: this.$t('deconve.setting.apiKey.apiKeyPermissions'),
      }).then((scopes) => {
        if (scopes) {
          const tags = this.tags.map((tag) => tag.id);

          const apiKeyData = {
            scopes,
            name: this.keyName,
            // eslint-disable-next-line @typescript-eslint/camelcase
            tag_ids: tags,
          };

          if (this.unitId) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            apiKeyData.unit_id = this.unitId;
          }

          createApiKey(apiKeyData).then((response) => {
            this.accessKey = response.key;
          });
        }
      });
    },
    confirm() {
      if (this.id) {
        this.editApiKey();
      } else {
        this.createApiKey();
      }
    },
    close() {
      this.resolve(this.accessKey);
      this.dialog = false;
    },
    cancel() {
      this.resolve(this.accessKey);
      this.dialog = false;
    },
    resetData() {
      this.keyName = '';
      this.id = '';
      this.tags = [];
      this.copied = false;
      this.accessKey = '';
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          if (this.id) {
            if (tags !== this.defaultTags) {
              this.organizeNewTags(tags);
            }
          } else {
            this.tagsToAdd = tags;
          }

          this.tags = tags;
        }
      });
    },
    organizeNewTags(tags) {
      this.tagsToAdd = [];
      this.tagsToDelete = [];
      const newTagsClone = tags.slice();

      this.defaultTags.forEach(({ tag }, index) => {
        const tagIndex = newTagsClone.findIndex(({ id }) => id === tag.id);
        const isTagDeleted = tagIndex === -1;

        if (isTagDeleted) {
          this.tagsToDelete.push(this.defaultTags[index].tag);
        } else {
          newTagsClone.splice(tagIndex, 1);
        }
      });
      this.tagsToAdd = newTagsClone;
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
      this.organizeNewTags(this.tags);
      this.copied = false;
      this.accessKey = '';
    },
    copyKey() {
      this.$refs.key.select();
      document.execCommand('copy');
      this.copied = true;
    },
  },
};
</script>

<style scoped>
  textarea:focus {
    outline: 0;
  }
</style>
