<template>
  <v-dialog
    v-model="dialog"
    max-width="480"
    @keydown.esc="cancel"
  >
    <v-card class="px-6 py-8">
      <v-toolbar
        dark
        color="white"
        dense
        flat
      >
        <v-toolbar-title class="h6 text-sm-h5 font-weight-bold textBlack--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="pa-4 my-4"
        style="max-height: 380px; overflowY: auto"
      >
        <v-treeview
          v-model="selectedScopes"
          :items="scopes"
          item-disabled="locked"
          hoverable
          selected-color="primary"
          selectable
          return-object
          data-cy="scopesmanager-list"
        />
      </v-card-text>
      <v-card-actions
        class="pt-2"
      >
        <v-spacer />
        <rectangle-button
          v-if="!locked"
          outlined
          color="neutral"
          data-cy="scopesmanager-cancel"
          @clicked="cancel"
        >
          {{ $t('deconve.cancel') }}
        </rectangle-button>
        <rectangle-button
          color="primary"
          data-cy="scopesmanager-confirm"
          @clicked="confirm"
        >
          {{ locked? $t('deconve.close') : $t('deconve.confirm') }}
        </rectangle-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import Vue from 'vue';
import RectangleButton from './RectangleButton.vue';

export default {
  name: 'ScopesManagerDialog',
  components: {
    RectangleButton,
  },
  data: () => ({
    selectedScopes: [],
    scopes: [],
    locked: false,
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
  }),
  methods: {
    open({ title, scopes, locked }) {
      this.selectedScopes = [];
      this.title = title;
      this.locked = locked;
      this.populateScopes();

      if (scopes) {
        this.selectDefaultScopes(scopes);
      }

      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    selectDefaultScopes(scopes) {
      this.scopes.forEach((mainScope) => {
        mainScope.children.forEach((firstLevelScope) => {
          if (firstLevelScope.children) {
            firstLevelScope.children.forEach((secondLevelScope) => {
              const isSelected = scopes.indexOf(secondLevelScope.value) !== -1;

              if (isSelected) {
                this.selectedScopes.push(secondLevelScope);
              }
            });
          } else {
            const isSelected = scopes.indexOf(firstLevelScope.value) !== -1;

            if (isSelected) {
              this.selectedScopes.push(firstLevelScope);
            }
          }
        });
      });
    },
    confirm() {
      const scopes = this.selectedScopes.map((selected) => selected.value);

      this.resolve(scopes);
      this.selectedScopes = [];
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
    populateScopes() {
      let scopes = [];

      const faceIdScopes = [];

      faceIdScopes.push(
        {
          id: 'com.deconve.faceid.person',
          name: this.$t('deconve.scopes.type.person'),
          children: [
            {
              id: 'create:com.deconve.faceid.person',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.faceid.person',
            },
            {
              id: 'read:com.deconve.faceid.person',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.faceid.person',
            },
            {
              id: 'update:com.deconve.faceid.person',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.faceid.person',
            },
            {
              id: 'delete:com.deconve.faceid.person',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.faceid.person',
            },
          ],
        },
      );

      if (this.$can('use', 'com.deconve.hub')) {
        faceIdScopes.push({
          id: 'com.deconve.faceid.superprofile',
          name: this.$t('deconve.scopes.type.superProfile'),
          children: [
            {
              id: 'create:com.deconve.faceid.superprofile',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.faceid.superprofile',
            },
            {
              id: 'read:com.deconve.faceid.superprofile',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.faceid.superprofile',
            },
            {
              id: 'update:com.deconve.faceid.superprofile',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.faceid.superprofile',
            },
            {
              id: 'delete:com.deconve.faceid.superprofile',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.faceid.superprofile',
            },
          ],
        });

        faceIdScopes.push({
          id: 'com.deconve.faceid.superprofile.image',
          name: this.$t('deconve.scopes.type.superProfileImage'),
          children: [
            {
              id: 'read:com.deconve.faceid.superprofile.image',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.faceid.superprofile.image',
            },
          ],
        });
      }

      faceIdScopes.push({
        id: 'com.deconve.faceid.notification',
        name: this.$t('deconve.scopes.type.notification'),
        children: [
          {
            id: 'create:com.deconve.faceid.notification',
            name: this.$t('deconve.scopes.permission.create'),
            value: 'create:com.deconve.faceid.notification',
          },
          {
            id: 'read:com.deconve.faceid.notification',
            name: this.$t('deconve.scopes.permission.read'),
            value: 'read:com.deconve.faceid.notification',
          },
          {
            id: 'update:com.deconve.faceid.notification',
            name: this.$t('deconve.scopes.permission.update'),
            value: 'update:com.deconve.faceid.notification',
          },
          {
            id: 'delete:com.deconve.faceid.notification',
            name: this.$t('deconve.scopes.permission.delete'),
            value: 'delete:com.deconve.faceid.notification',
          },
        ],
      });

      faceIdScopes.push({
        id: 'com.deconve.faceid.occurrence',
        name: this.$t('deconve.scopes.type.occurrence'),
        children: [
          {
            id: 'create:com.deconve.faceid.occurrence',
            name: this.$t('deconve.scopes.permission.create'),
            value: 'create:com.deconve.faceid.occurrence',
          },
          {
            id: 'read:com.deconve.faceid.occurrence',
            name: this.$t('deconve.scopes.permission.read'),
            value: 'read:com.deconve.faceid.occurrence',
          },
          {
            id: 'update:com.deconve.faceid.occurrence',
            name: this.$t('deconve.scopes.permission.update'),
            value: 'update:com.deconve.faceid.occurrence',
          },
          {
            id: 'delete:com.deconve.faceid.occurrence',
            name: this.$t('deconve.scopes.permission.delete'),
            value: 'delete:com.deconve.faceid.occurrence',
          },
        ],
      });

      faceIdScopes.push({
        id: 'com.deconve.faceid.model',
        name: this.$t('deconve.scopes.type.model'),
        children: [
          {
            id: 'create:com.deconve.faceid.model',
            name: this.$t('deconve.scopes.permission.create'),
            value: 'create:com.deconve.faceid.model',
          },
          {
            id: 'read:com.deconve.faceid.model',
            name: this.$t('deconve.scopes.permission.read'),
            value: 'read:com.deconve.faceid.model',
          },
          {
            id: 'update:com.deconve.faceid.model',
            name: this.$t('deconve.scopes.permission.update'),
            value: 'update:com.deconve.faceid.model',
          },
          {
            id: 'delete:com.deconve.faceid.model',
            name: this.$t('deconve.scopes.permission.delete'),
            value: 'delete:com.deconve.faceid.model',
          },
        ],
      });

      faceIdScopes.push({
        id: 'com.deconve.faceid.report',
        name: this.$t('deconve.scopes.type.report'),
        children: [
          {
            id: 'create:com.deconve.faceid.report',
            name: this.$t('deconve.scopes.permission.create'),
            value: 'create:com.deconve.faceid.report',
          },
          {
            id: 'read:com.deconve.faceid.report',
            name: this.$t('deconve.scopes.permission.read'),
            value: 'read:com.deconve.faceid.report',
          },
          {
            id: 'update:com.deconve.faceid.report',
            name: this.$t('deconve.scopes.permission.update'),
            value: 'update:com.deconve.faceid.report',
          },
          {
            id: 'delete:com.deconve.faceid.report',
            name: this.$t('deconve.scopes.permission.delete'),
            value: 'delete:com.deconve.faceid.report',
          },
        ],
      });

      const peopleCounterScopes = [
        {
          id: 'com.deconve.peoplecounter.report',
          name: this.$t('deconve.scopes.type.report'),
          children: [
            {
              id: 'create:com.deconve.peoplecounter.report',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.peoplecounter.report',
            },
            {
              id: 'read:com.deconve.peoplecounter.report',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.peoplecounter.report',
            },
            {
              id: 'update:com.deconve.peoplecounter.report',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.peoplecounter.report',
            },
            {
              id: 'delete:com.deconve.peoplecounter.report',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.peoplecounter.report',
            },
          ],
        },
      ];

      const vehicleCounterScopes = [
        {
          id: 'com.deconve.vehiclecounter',
          name: this.$t('deconve.scopes.type.report'),
          children: [
            {
              id: 'create:com.deconve.vehiclecounter.report',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.vehiclecounter.report',
            },
            {
              id: 'read:com.deconve.vehiclecounter.report',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.vehiclecounter.report',
            },
            {
              id: 'update:com.deconve.vehiclecounter.report',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.vehiclecounter.report',
            },
            {
              id: 'delete:com.deconve.vehiclecounter.report',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.vehiclecounter.report',
            },
          ],
        },
      ];

      const retailAnalyticsScopes = [
        {
          id: 'com.deconve.retailanalytics.report',
          name: this.$t('deconve.scopes.type.report'),
          children: [
            {
              id: 'create:com.deconve.retailanalytics.report',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.retailanalytics.report',
            },
            {
              id: 'read:com.deconve.retailanalytics.report',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.retailanalytics.report',
            },
            {
              id: 'update:com.deconve.retailanalytics.report',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.retailanalytics.report',
            },
            {
              id: 'delete:com.deconve.retailanalytics.report',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.retailanalytics.report',
            },
          ],
        },
      ];

      const lprScopes = [
        {
          id: 'com.deconve.lpr.vehicle',
          name: this.$t('deconve.scopes.type.vehicle'),
          children: [
            {
              id: 'create:com.deconve.lpr.vehicle',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.lpr.vehicle',
            },
            {
              id: 'read:com.deconve.lpr.vehicle',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.lpr.vehicle',
            },
            {
              id: 'update:com.deconve.lpr.vehicle',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.lpr.vehicle',
            },
            {
              id: 'delete:com.deconve.lpr.vehicle',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.lpr.vehicle',
            },
          ],
        },
        {
          id: 'com.deconve.lpr.notification',
          name: this.$t('deconve.scopes.type.notification'),
          children: [
            {
              id: 'create:com.deconve.lpr.notification',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.lpr.notification',
            },
            {
              id: 'read:com.deconve.lpr.notification',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.lpr.notification',
            },
            {
              id: 'update:com.deconve.lpr.notification',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.lpr.notification',
            },
            {
              id: 'delete:com.deconve.lpr.notification',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.lpr.notification',
            },
          ],
        },
        {
          id: 'com.deconve.lpr.report',
          name: this.$t('deconve.scopes.type.report'),
          children: [
            {
              id: 'create:com.deconve.lpr.report',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.lpr.report',
            },
            {
              id: 'read:com.deconve.lpr.report',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.lpr.report',
            },
            {
              id: 'update:com.deconve.lpr.report',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.lpr.report',
            },
            {
              id: 'delete:com.deconve.lpr.report',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.lpr.report',
            },
          ],
        },
      ];

      if (Vue.prototype.$ability.can('use', 'com.deconve.id')) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.faceid',
            locked: this.locked,
            name: this.$t('deconve.faceId'),
            children: faceIdScopes,
          }]);
      }

      if (Vue.prototype.$ability.can('use', 'com.deconve.analytics.peopleflow')) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.peoplecounter',
            name: this.$t('deconve.scopes.peopleCounter'),
            locked: this.locked,
            children: peopleCounterScopes,
          },
        ]);
      }

      const canUseAnalyticsDooh = Vue.prototype.$ability.can('use', 'com.deconve.analytics.dooh');
      const canUseAnalyticsVehicleFlow = Vue.prototype.$ability.can('use', 'com.deconve.analytics.vehicleflow');

      if (canUseAnalyticsDooh && canUseAnalyticsVehicleFlow) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.vehiclecounter.report',
            name: this.$t('deconve.scopes.doohAndVehicleFlowAnalytics'),
            locked: this.locked,
            children: vehicleCounterScopes,
          },
        ]);
      } else if (canUseAnalyticsDooh) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.vehiclecounter.report',
            name: this.$t('deconve.scopes.doohAnalytics'),
            locked: this.locked,
            children: vehicleCounterScopes,
          },
        ]);
      } else if (canUseAnalyticsVehicleFlow) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.vehiclecounter.report',
            name: this.$t('deconve.scopes.vehicleFlowAnalytics'),
            locked: this.locked,
            children: vehicleCounterScopes,
          },
        ]);
      }

      if (Vue.prototype.$ability.can('use', 'com.deconve.analytics.retail')) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.retailanalytics',
            name: this.$t('deconve.scopes.retailAnalytics'),
            locked: this.locked,
            children: retailAnalyticsScopes,
          },
        ]);
      }

      if (Vue.prototype.$ability.can('use', 'com.deconve.lpr')) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.lpr',
            name: this.$t('deconve.scopes.vehicleLicensePlateRecognition'),
            locked: this.locked,
            children: lprScopes,
          },
        ]);
      }

      if (Vue.prototype.$ability.can('use', 'com.deconve.caremonitor')) {
        scopes = scopes.concat([
          {
            id: 'com.deconve.caremonitor',
            name: this.$t('deconve.scopes.careMonitor'),
            locked: this.locked,
            children: [
              {
                id: 'com.deconve.caremonitor.report',
                name: this.$t('deconve.scopes.type.report'),
                children: [
                  {
                    id: 'create:com.deconve.caremonitor.report',
                    name: this.$t('deconve.scopes.permission.create'),
                    value: 'create:com.deconve.caremonitor.report',
                  },
                  {
                    id: 'read:com.deconve.caremonitor.report',
                    name: this.$t('deconve.scopes.permission.read'),
                    value: 'read:com.deconve.caremonitor.report',
                  },
                  {
                    id: 'update:com.deconve.caremonitor.report',
                    name: this.$t('deconve.scopes.permission.update'),
                    value: 'update:com.deconve.caremonitor.report',
                  },
                  {
                    id: 'delete:com.deconve.caremonitor.report',
                    name: this.$t('deconve.scopes.permission.delete'),
                    value: 'delete:com.deconve.caremonitor.report',
                  },
                ],
              },
            ],
          },
        ]);
      }

      scopes = scopes.concat([
        {
          id: 'com.deconve.video',
          name: this.$t('deconve.scopes.videos'),
          locked: this.locked,
          children: [
            {
              id: 'create:com.deconve.video',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.video',
            },
            {
              id: 'read:com.deconve.video',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.video',
            },
            {
              id: 'update:com.deconve.video',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.video',
            },
            {
              id: 'delete:com.deconve.video',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.video',
            },
          ],
        },
        {
          id: 'com.deconve.unit',
          name: this.$t('deconve.scopes.units'),
          locked: this.locked,
          children: [
            {
              id: 'create:com.deconve.unit',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.unit',
            },
            {
              id: 'read:com.deconve.unit',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.unit',
            },
            {
              id: 'update:com.deconve.unit',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.unit',
            },
            {
              id: 'delete:com.deconve.unit',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.unit',
            },
          ],
        },
        {
          id: 'com.deconve.campaign',
          name: this.$t('deconve.scopes.campaigns'),
          locked: this.locked,
          children: [
            {
              id: 'create:com.deconve.campaign',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.campaign',
            },
            {
              id: 'read:com.deconve.campaign',
              name: this.$t('deconve.scopes.permission.read'),
              value: 'read:com.deconve.campaign',
            },
            {
              id: 'update:com.deconve.campaign',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.campaign',
            },
            {
              id: 'delete:com.deconve.campaign',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.campaign',
            },
          ],
        },
        // {
        //   id: 'com.deconve.application.status',
        //   name: this.$t('deconve.scopes.type.status'),
        //   locked: this.locked,
        //   children: [
        //     {
        //       id: 'read:com.deconve.application.status',
        //       name: this.$t('deconve.scopes.permission.read'),
        //       value: 'read:com.deconve.application.status',
        //     },
        //   ],
        // },
        {
          id: 'com.deconve.tag',
          name: this.$t('deconve.scopes.type.tags'),
          locked: this.locked,
          children: [
            {
              id: 'create:com.deconve.tag',
              name: this.$t('deconve.scopes.permission.create'),
              value: 'create:com.deconve.tag',
            },
            {
              id: 'update:com.deconve.tag',
              name: this.$t('deconve.scopes.permission.update'),
              value: 'update:com.deconve.tag',
            },
            {
              id: 'delete:com.deconve.tag',
              name: this.$t('deconve.scopes.permission.delete'),
              value: 'delete:com.deconve.tag',
            },
          ],
        },
      ]);

      // Public permissions for anyone logged in the workspace:
      // - read apikeys, users, tags and workspace settings

      // Only admin can:
      // - change workspace settings
      // - create, update or delete api key and user
      this.scopes = scopes;
    },
  },
};
</script>
